@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;1,300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Eagle+Lake&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Constantine;
  src: url(/static/media/Constantine.3ee1143b.ttf);
}

.home-container {
  display: flex;
  background-image: url(/static/media/cover-zoom-characters3.9b46b32b.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 110vh;
  position: relative;
  width: 100vw;
  background-position: 73%;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
}

.home-gradient {
  display: flex;
  height: 110vh;
  width: 100vw;
  position: absolute;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
  background-image: linear-gradient(75deg, rgba(55, 26, 69, 0.8), rgba(77, 51, 73, 0.8), rgba(42, 76, 56, 0.8), rgba(62, 105, 80, 0.8), rgba(144, 85, 144, 0.8), rgba(88, 25, 88, 0.8));
  background-size: 400%;
  animation: gradientAnimation ease-in-out 10s infinite alternate;
}

.home-content-container {
  display: flex;
  width: 100vw;
  height: 70%;
  justify-content: center;
  padding: 5% 10% 0 10%;
  align-content: center;
}

.home-description-retailer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 700px;
  padding-left: 90px;
  color: white;
  height: auto;
  animation: moveInUp 3s ease-out;
  animation: moveInUp 2s ease-in;
}

.home-description-retailer-container p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.home-description-retailer-container link {
  text-decoration: none;
  font-size: 25px;
  color: white;
  cursor: pointer;
  text-align: right;
}

.home-description-retailer-container link:hover {
  border-bottom: 3px solid #385538;
}

.home-description-retailer-container h1, .home-description-retailer-container h2 {
  text-align: "left";
  font-size: 3rem;
  text-shadow: 2px 3px 5px #2e4a64;
  text-align: center;
  margin-bottom: 0px;
  padding: 0;
  font-family: Constantine;
}

.home-description-retailer-container h2 {
  font-size: 2rem;
  padding-top: 90px;
}

.book-description {
  font-family: Constantine;
  font-size: 2.2rem;
  text-align: center;
}

.book-description2 {
  font-family: Roboto;
  font-size: 1.3rem;
  text-align: center;
}

.book-learn-more {
  display: inline-block;
  text-decoration: underline;
  font-family: Roboto;
  padding-top: 5px;
  font-size: 1.1rem;
  width: 100px;
  padding-left: 9px;
}

.book-learn-more:hover {
  cursor: pointer;
}

.home-cover-preview-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  animation: moveInLeft 3s ease-out;
}

.home-cover-preview-container img {
  margin-bottom: 10px;
  height: 400px;
  border-radius: 5px;
}

.home-retailer-container {
  display: flex;
  justify-content: center;
}

.home-retailer-container p {
  font-size: 1.2rem;
}

.preview-button-container {
  display: flex;
  width: 250px;
}

.preview-button-container button {
  font-family: 'Helvetica', Courier, sans-serif;
  outline: none;
  border: none;
  background-color: white;
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  margin: 5px;
  border-radius: 5px;
}

.preview-button-container button:hover {
  cursor: pointer;
  background-color: #c1c1c1;
}

.dropdown-menu {
  background-color: white;
  flex-direction: column;
  margin-top: 80px;
  color: black;
}

.dropdown-menu a {
  padding: 10px;
  font-family: 'Helvetica', Courier, sans-serif;
  font-size: 1rem;
  text-decoration: none;
  color: black;
}

@media (max-width: 630px) {
  .dropdown-menu a {
    font-size: 0.9rem;
  }
}

.dropdown-menu a:hover {
  background-color: #6b8696;
  color: white;
  cursor: pointer;
}

.preview-dropdown-open {
  display: flex;
  position: absolute;
  z-index: 5;
  margin-top: 43px;
  margin-left: 15px;
  margin-right: 10px;
  width: 220px;
}

.preview-dropdown-closed {
  display: none;
}

.retail-link {
  text-decoration: none;
  color: black;
  font-family: Helvetica, Courier, sans-serif;
  font-size: 1rem;
  background-color: white;
  height: 70px;
  margin: 10px;
  border-radius: 5px;
  width: 190px;
  border: none;
  text-align: center;
  padding-top: 25.5px;
  box-sizing: border-box;
}

.retail-link:hover {
  cursor: pointer;
  background-color: #c1c1c1;
}

.retail-button {
  padding-top: 0px;
  padding-bottom: 0px;
}

.retail-button:hover {
  cursor: pointer;
  background-color: #c1c1c1;
}

.retail-dropdown-container {
  position: relative;
}

.retail-dropdown-open {
  display: flex;
  position: absolute;
  margin-top: -10px;
  margin-left: 20px;
  width: 170px;
}

@media (max-width: 630px) {
  .retail-dropdown-open {
    width: 90px;
  }
}

.retail-dropdown-closed {
  display: none;
}

@keyframes moveInUp {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  /* 80% {
     transform: translateX(10px);
  } */
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  /* 80% {
    transform: translateX(-10px);
 } */
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@media (max-width: 1200px) {
  .home-container {
    background-position: 73%;
  }
  .home-container img {
    height: 320px;
  }
  .home-content-container {
    padding-left: 3%;
  }
  .home-description-retailer-container {
    padding-left: 20px;
    width: 600px;
  }
  .book-description {
    font-size: 2rem;
  }
  .book-description2 {
    font-size: 1.1rem;
  }
}

@media (max-width: 900px) and (min-height: 500px) {
  .home-container {
    background-position: 80%;
  }
  .home-container img {
    height: 300px;
  }
  .home-content-container {
    flex-direction: column;
    padding: 100px 0px;
    align-items: center;
  }
  .home-cover-preview-container {
    order: 2;
    padding-top: 10px;
  }
  .preview-button-container {
    width: 160px;
  }
  .bookdescription2 {
    margin-right: 20px;
  }
}

@media (max-width: 630px) {
  .home-container {
    height: 120vh;
    background-image: url(/static/media/cover-zoom-characters-mobile.c2d7c735.png);
    background-position: 53%;
  }
  .home-container img {
    height: 230px;
    margin-top: 10px;
  }
  .home-container h2 {
    padding-top: 30px;
    font-size: 1.7rem;
  }
  .home-gradient {
    height: 120vh;
  }
  .home-description-retailer-container {
    padding-top: 10px;
    width: 100vw;
  }
  .home-description-retailer-container h1 {
    font-size: 2.1rem;
    padding-right: 0px;
  }
  .book-description {
    font-size: 1.5rem;
  }
  .book-description2 {
    font-size: 1.1rem;
  }
  .home-retailer-cotainer button {
    height: 50px;
  }
  .home-description-retailer-container {
    padding-top: 10px;
    padding-left: 0;
  }
  .preview-button-container {
    width: 250px;
  }
  .retail-link {
    font-size: 14px;
    padding: 10px;
    height: 50px;
    width: 109px;
    min-width: 85px;
  }
  .amazon {
    line-height: 35px;
  }
  .open-up {
    margin-top: -65px;
  }
}

@media (max-height: 500px) {
  .home-container {
    background-position: 80%;
  }
  .home-container img {
    height: 230px;
    margin-top: 10px;
  }
  .home-content-container {
    padding: 70px 0px;
    align-items: center;
  }
  .home-cover-preview-container {
    padding-top: 10px;
    padding-left: 10px;
  }
  .preview-button-container {
    width: 160px;
  }
  .bookdescription2 {
    margin-right: 20px;
  }
  h2 {
    padding-top: 30px;
    font-size: 1.7rem;
  }
  .home-description-retailer-container {
    padding-top: 10px;
    width: 100vw;
  }
  .home-description-retailer-container h1 {
    font-size: 2.1rem;
    padding-right: 0px;
  }
  .home-description-retailer-container h2 {
    padding-top: 10px;
  }
  .book-description {
    font-size: 1.5rem;
  }
  .book-description2 {
    font-size: 1.1rem;
  }
  .home-retailer-cotainer button {
    height: 50px;
  }
  .home-description-retailer-container {
    padding-top: 10px;
    padding-left: 0;
  }
  .preview-button-container {
    width: 140px;
  }
  .retail-link {
    padding: 8px;
    height: 50px;
    width: 130px;
  }
  .retail-button {
    padding-top: 0px;
  }
  .amazon {
    line-height: 35px;
  }
  .open-up {
    margin-top: -119px;
    margin-left: 8px;
  }
  .open-down {
    margin-top: 46px;
    margin-left: 8px;
  }
}

.about-background-container {
  background-color: #f5e9d4;
  height: 85vh;
  min-height: 700px;
  padding-top: 50px;
  margin-top: -10vh;
  position: relative;
}

@media (max-width: 800px) {
  .about-background-container {
    height: auto;
  }
}

@media (max-width: 500px) {
  .about-background-container {
    height: 84vh;
  }
}

@media (max-height: 500px) {
  .about-background-container {
    height: 100vh;
    margin-top: -108px;
    min-height: 500px;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  position: relative;
  height: 100%;
}

.about-container p {
  padding-left: 50px;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 850px) {
  .about-container p {
    font-size: 2.3vw;
  }
}

@media (max-width: 1200px) {
  .about-container p {
    font-size: 1.2rem;
  }
}

@media (max-width: 700px) {
  .about-container p {
    padding-right: 20px;
    width: auto;
  }
}

@media (max-width: 500px) {
  .about-container p {
    font-size: 3vw;
  }
}

@media (max-height: 500px) {
  .about-container p {
    font-size: 0.8rem;
    padding-left: 10px;
    margin: 3px 0;
  }
}

.about-container h1 {
  padding: 0px;
  z-index: 5;
}

@media (max-width: 500px) {
  .about-container h1 {
    font-size: 1.5rem;
  }
}

@media (max-height: 500px) {
  .about-container h1 {
    margin-bottom: 5px;
    font-size: 1.3rem;
  }
}

.about-container h2 {
  z-index: 5;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 500px) {
  .about-container h2 {
    font-size: 1.2rem;
  }
}

@media (max-height: 500px) {
  .about-container h2 {
    font-size: 1.2rem;
    padding-bottom: 0;
  }
}

.about-container::before {
  content: "";
  position: absolute;
  top: -50vh;
  left: -35vw;
  width: 55vw;
  height: calc(100% + 50vh);
  background-color: #ff9100;
  transform: skew(11deg);
  transform-origin: top;
}

@media (min-width: 1500px) {
  .about-container::before {
    width: 65vw;
  }
}

@media (max-width: 1200px) {
  .about-container::before {
    width: 60vw;
    left: -40vw;
  }
}

@media (max-width: 700px) {
  .about-container::before {
    top: -50vh;
    left: -50vw;
    width: 60vw;
  }
}

@media (max-width: 500px) {
  .about-container::before {
    left: -65vw;
    width: 50vw;
  }
}

@media (max-width: 400px) {
  .about-container::before {
    left: -85vw;
    width: 60vw;
  }
}

.about-container .picture-description-container {
  display: flex;
  z-index: 3;
  width: 900px;
  justify-content: center;
}

@media (min-width: 1500px) {
  .about-container .picture-description-container {
    width: 1000px;
    min-width: 60vw;
    padding-left: 200px;
  }
}

@media (max-width: 1200px) {
  .about-container .picture-description-container {
    width: 700px;
    max-width: 80vw;
    padding-left: 50px;
  }
}

@media (max-width: 700px) {
  .about-container .picture-description-container {
    width: 100vw;
    flex-wrap: wrap;
    padding-left: 0;
  }
}

@media (max-width: 500px) {
  .about-container .picture-description-container {
    padding-left: 0px;
  }
}

@media (max-height: 500px) {
  .about-container .picture-description-container {
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 8px;
    max-width: 95vw;
  }
}

.about-container .picture-description-container .jason {
  min-width: 300px;
  min-height: 300px;
  width: 300px;
  height: 300px;
  border: 30px solid #ff9100;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .about-container .picture-description-container .jason {
    width: 200px;
    height: 200px;
    min-width: 200px;
    min-height: 200px;
    border-width: 20px;
  }
}

@media (max-width: 700px) {
  .about-container .picture-description-container .jason {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
    border: none;
  }
}

@media (max-height: 500px) {
  .about-container .picture-description-container .jason {
    border: 10px solid #ff9100;
  }
}

.about-container .picture-description-container .about-paragraph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-height: 500px) {
  .about-container .picture-description-container .about-paragraph-container {
    font-size: 0.8rem;
  }
}

.about-container .social-media-container {
  display: flex;
  z-index: 10;
}

.about-container .social-media-container .social-media {
  width: 50px;
  padding: 10px;
  z-index: 3;
  border-radius: 20px;
}

@media (max-width: 500px) {
  .about-container .social-media-container .social-media {
    width: 10vw;
  }
}

@media (max-height: 500px) {
  .about-container .social-media-container .social-media {
    width: 30px;
  }
}

.about-container .social-media-container .social-media:hover {
  cursor: pointer;
  -webkit-filter: hue-rotate(250);
          filter: hue-rotate(250);
}

@font-face {
  font-family: Constantine;
  src: url(/static/media/Constantine.3ee1143b.ttf);
}

.characters-fade-container {
  width: 100vw;
  height: 105vh;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
  background-color: black;
  z-index: 3;
  position: relative;
  padding-bottom: 5vh;
  min-height: 900px;
}

@media (max-width: 700px) {
  .characters-fade-container {
    height: 95vh;
  }
}

@media (max-width: 500px) {
  .characters-fade-container {
    height: 102vh;
  }
}

@media (max-height: 500px) {
  .characters-fade-container {
    min-height: 105vh;
  }
}

.characters-main-container {
  background-image: url(/static/media/background-shadow-left3.b5918563.jpg);
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
  width: 80vw;
  margin-top: -10px;
  min-height: 800px;
  margin-left: 10vw;
}

@media (max-width: 1200px) {
  .characters-main-container {
    height: 90vh;
    width: 90vw;
    margin-left: 5vw;
  }
}

@media (max-width: 900px) {
  .characters-main-container {
    background-position: 25%;
    width: 98vw;
    margin-left: 1vw;
  }
}

@media (max-width: 700px) {
  .characters-main-container {
    width: 100vw;
    height: 95vh;
  }
}

@media (max-height: 500px) {
  .characters-main-container {
    min-height: 80vh;
    max-height: 90vh;
    width: 90vw;
    margin-left: 5vw;
  }
}

.characters-main-container .characters-container {
  justify-content: center;
  height: 100vh;
  position: relative;
  display: -ms-flexbox;
  transition: -webkit-backdrop-filter 2s;
  transition: backdrop-filter 2s;
  transition: backdrop-filter 2s, -webkit-backdrop-filter 2s;
  display: flex;
  cursor: hand;
  width: 80vw;
  box-shadow: inset 0px -2px 100px 100px black;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 800px;
}

@media (max-width: 1200px) {
  .characters-main-container .characters-container {
    width: 90vw;
  }
}

@media (max-width: 900px) {
  .characters-main-container .characters-container {
    width: 98vw;
    box-shadow: inset 0px -2px 50px 50px black;
  }
}

@media (max-width: 700px) {
  .characters-main-container .characters-container {
    width: 100vw;
    height: 95vh;
    box-shadow: inset 0px -2px 40px 30px black;
  }
}

@media (max-height: 500px) {
  .characters-main-container .characters-container {
    min-height: 400px;
    box-shadow: inset 0px -2px 40px 30px black;
    width: 100%;
    height: 100%;
  }
}

.characters-main-container .characters-container.alim {
  background-color: #73730da1;
  transition: background-color 2s;
  backdrop-filter: hue-rotate(305deg) contrast(2);
  -webkit-backdrop-filter: hue-rotate(260deg) contrast(2);
}

.characters-main-container .characters-container.tio {
  -webkit-backdrop-filter: hue-rotate(80deg) contrast(2);
  backdrop-filter: hue-rotate(80deg) contrast(2);
  background-color: rgba(0, 128, 0, 0.329412);
  transition: background-color 2s;
}

.characters-main-container .characters-container.hali {
  background-color: #710080a6;
  -webkit-backdrop-filter: hue-rotate(210deg) contrast(2);
  transition: background-color 2s;
  -webkit-backdrop-filter: hue-rotate(195deg) contrast(2);
          backdrop-filter: hue-rotate(195deg) contrast(2);
}

.character-container {
  display: flex;
  flex-direction: column;
  box-shadow: 20px 20px 20px #1b1414;
  justify-content: flex-start;
  position: absolute;
  margin: 100px 10px 0px 10px;
  width: 560px;
  padding: 25px 5px 5px 5px;
  min-height: 450px;
  max-height: 1000px;
  height: auto;
  z-index: 2;
  opacity: 1;
  transition: opacity 2s;
  align-items: center;
  border: none;
  border-radius: 0px;
}

@media (max-width: 900px) {
  .character-container {
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .character-container {
    width: 80vw;
    margin-top: 50px;
  }
}

@media (max-height: 500px) {
  .character-container {
    width: clamp(500px, 70vw, 900px);
    margin-top: 40px;
    min-height: 60vh;
    justify-content: center;
  }
}

.character-container.tio {
  background-image: radial-gradient(#008000c7, #075d0724);
}

.character-container.alim {
  background-image: radial-gradient(rgba(242, 247, 64, 0.27), #8c71256b);
}

.character-container.hali {
  background-color: rgba(88, 25, 88, 0.57);
}

.character-container .prev,
.character-container .next {
  cursor: pointer;
  position: absolute;
  top: 20%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: transparent;
  border: none;
}

@media (max-width: 500px) {
  .character-container .prev p,
  .character-container .next p {
    font-size: 1.1rem;
  }
}

.character-container .prev {
  left: 0;
}

.character-container .next {
  right: 0;
  text-align: right;
  border-radius: 3px 0 0 3px;
}

.character-container .arrow-name {
  font-family: Constantine;
  font-size: 1.2rem;
}

@media (max-height: 500px) {
  .character-container .img-desc-container {
    display: flex;
  }
}

@media (max-height: 500px) {
  .character-container .img-desc-container {
    display: flex;
  }
}

.character-container .img-desc-container .image-name-container {
  height: auto;
}

@media (max-height: 500px) {
  .character-container .img-desc-container .image-name-container {
    margin-left: 25px;
  }
}

.character-container .img-desc-container .image-name-container .character-img {
  width: 300px;
  width: 318px;
}

@media (max-width: 700px) {
  .character-container .img-desc-container .image-name-container .character-img {
    margin-top: 50px;
    width: 200px;
    height: 200px;
    margin-top: 0px;
  }
}

@media (max-height: 500px) {
  .character-container .img-desc-container .image-name-container .character-img {
    width: 150px;
    height: 150px;
    margin-left: 30px;
  }
}

@media (min-height: 2500px) {
  .character-container .img-desc-container .image-name-container .character-img {
    width: 409px;
  }
}

.character-container .img-desc-container .character-description {
  padding: 10px;
  margin: 0px 10px 10px 10px;
  border-width: 10px;
  border-style: solid;
  height: auto;
  overflow: hidden;
  transition: height 2s;
  color: white;
  font-size: 1.1rem;
  border-image: url(/static/media/borderhali2.814d6dbb.png) 70 repeat;
}

@media (max-width: 700px) {
  .character-container .img-desc-container .character-description {
    padding: 15px;
    margin: 10px;
    border-width: 8px;
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .character-container .img-desc-container .character-description {
    font-size: 0.95rem;
  }
}

@media (max-height: 500px) {
  .character-container .img-desc-container .character-description {
    font-size: 0.8rem;
    margin-right: 65px;
    max-width: 500px;
  }
}

@media (min-height: 2500px) {
  .character-container .img-desc-container .character-description {
    font-size: 2rem;
  }
}

.character-container .img-desc-container .character-description .character-quote {
  font-style: italic;
  font-family: Constantine;
  text-align: center;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  display: block;
}

@media (max-width: 700px) {
  .character-container .img-desc-container .character-description .character-quote {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

@media (max-width: 500px) {
  .character-container .img-desc-container .character-description .character-quote {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
}

@media (max-height: 500px) {
  .character-container .img-desc-container .character-description .character-quote {
    font-size: 1rem;
    line-height: 25px;
  }
}

@media (min-height: 2500px) {
  .character-container .img-desc-container .character-description .character-quote {
    font-size: 2.9rem;
  }
}

.character-container .img-desc-container .character-description .character-quote .quotation-marks {
  font-size: 3rem;
  line-height: 1rem;
  position: relative;
  top: 10px;
}

@media (max-width: 700px) {
  .character-container .img-desc-container .character-description .character-quote .quotation-marks {
    font-size: 2rem;
  }
}

@media (max-width: 500px), (max-height: 500px) {
  .character-container .img-desc-container .character-description .character-quote .quotation-marks {
    font-size: 2.5rem;
    line-height: 0rem;
  }
}

@media (min-height: 2500px) {
  .character-container .img-desc-container .character-description .character-quote .quotation-marks {
    font-size: 5rem;
  }
}

.character-container .img-desc-container .character-description.alim {
  background-color: #c3a921ad;
}

.character-container .img-desc-container .character-description.tio {
  background-color: rgba(46, 82, 46, 0.6);
}

.character-container .img-desc-container .character-description.hali {
  background-color: #7d5c7d94;
}

.character-container h1 {
  padding: 5px;
  margin: 0px;
  color: white;
}

@media (max-width: 500px) {
  .character-container h1 {
    font-size: 1.8rem;
  }
}

.inactive {
  z-index: 0;
  opacity: 0;
  transition: opacity 2s;
}

.footer {
  text-align: center;
  font-size: 10px;
  width: 100%;
  color: white;
  height: 50px;
  position: relative;
  /*fixed= stays at bottom but scrolls up? relative= stays away from bottom*/
  bottom: 0%;
  overflow: hidden;
  margin: auto;
  background-color: #243749;
}

.transition-main-container {
  height: 20vh;
  background-color: black;
  box-shadow: inset 5px 3px 10px 3px black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  padding-top: 100px;
}

.transition-main-container h1 {
  color: white;
  padding: 0;
  margin: 0;
}

.navbar-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 64px;
  top: 0;
  z-index: 10;
}

.navbar-container ul {
  list-style-type: none;
  position: relative;
  top: 0%;
  margin: 0;
  padding: 0;
  left: 0;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: #2e4a64;
  width: 100%;
  align-items: flex-start;
}

.navbar-container li {
  float: left;
  padding: 0px 10px 0px 10px;
}

.navbar-container li a {
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 4px solid transparent;
  padding: 0px 2px 0px 2px;
  font-size: 18px;
  display: block;
  border-bottom: 0;
  transition: border-color 1s;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

.navbar-container li a:hover {
  border-bottom: 4px solid white;
  color: #6490a0;
  cursor: pointer;
}

.header-title {
  padding: 0px;
  color: white;
  text-decoration: none;
  margin: 0px;
  font-family: 'Constantine';
  font-size: 20px;
}

.header-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  justify-content: center;
}

.header-title-container img {
  width: 200px;
  height: 28px;
}

.social {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.social img {
  width: 100%;
}

.mobile-menu-container {
  display: none;
}

@media (max-width: 900px) {
  .navbar-container li {
    padding: 0px 4px;
  }
  .navbar-container li a {
    padding: 7px 8px;
  }
  .instagram {
    padding-top: 7px;
  }
}

@media (max-width: 750px) {
  .navbar-container {
    display: none;
  }
  .mobile-menu-container {
    display: flex;
    position: fixed;
    color: white;
    width: 100vw;
    height: 60px;
    top: 0;
    z-index: 10;
    justify-content: space-evenly;
    overflow: hidden;
    background-color: #2e4a64;
  }
  .mobile-menu-container li {
    list-style: none;
  }
  .mobile-menu-container a {
    text-decoration: none;
  }
  .mobile-menu-navigation {
    background-color: #2e4a64;
    color: white;
    font-size: 2rem;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vw;
    height: 100vh;
  }
  .mobile-menu-navigation li {
    list-style: none;
    margin-top: 10vh;
    border-bottom: 4px solid transparent;
  }
  .mobile-menu-navigation li:hover {
    border-bottom: 4px solid white;
    color: #6490a0;
    cursor: pointer;
  }
}

.world-main-container {
  height: 110vh;
  position: relative;
  background-image: url(/static/media/background-painting3.f6d7b4b4.jpg);
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
  box-shadow: inset 5px 30px 50px 24px black;
  margin-top: -100px;
  padding-top: 100px;
}

.world-main-container-gradient {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 110vh;
  margin-top: -100px;
  padding-top: 100px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
  background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.75), #652500);
}

.world-main-container-gradient h1 {
  color: white;
  padding: 50px 0 0 0;
}

.world-map-container {
  height: 80vh;
  position: relative;
}

.world-map {
  height: 100%;
  box-shadow: 5px 5px 10px 4px black;
}

.map-locations {
  background-size: cover;
  display: flex;
  height: 5%;
  width: 5%;
  position: absolute;
  background-image: radial-gradient(#123110, #0a801f0f, transparent);
}

.map-locations:hover {
  cursor: pointer;
  transform: translate(3px, 3px);
  background-image: none;
  -webkit-filter: hue-rotate(300deg) brightness(1.7);
          filter: hue-rotate(300deg) brightness(1.7);
}

.map-locations img {
  width: 38px;
  height: 30px;
  width: 70%;
  height: 70%;
}

.description {
  background-color: #652500e3;
  padding: 20px;
  width: 450px;
  border: 4px solid black;
  color: white;
  opacity: 0.9;
  z-index: 3;
}

.description h2 {
  font-family: Constantine;
}

.map-icon {
  width: 20px;
  height: 20px;
}

.active-map-element {
  transform: translate(3px, 3px);
  background-image: none;
  -webkit-filter: hue-rotate(300deg) brightness(1.7);
          filter: hue-rotate(300deg) brightness(1.7);
}

@media (max-width: 1000px) {
  .world-map-container {
    height: 60vh;
  }
}

@media (max-width: 800px) {
  .world-map-container {
    height: 50vh;
  }
  .world-main-container {
    height: 80vh;
  }
  .world-main-container-gradient {
    height: 80vh;
  }
}

@media (max-width: 650px) {
  .world-map-container {
    height: 35vh;
  }
  .world-main-container {
    height: 80vh;
  }
  .world-main-container-gradient {
    height: 80vh;
  }
  .description {
    font-size: 0.8rem;
    width: 250px;
  }
  .description h2 {
    font-size: 1.2rem;
  }
}

@media (max-height: 500px) {
  .world-main-container, .world-main-container-gradient {
    height: 120vh;
  }
  .world-main-container h1, .world-main-container-gradient h1 {
    font-size: 1.3rem;
    margin-bottom: 5px;
  }
  .world-map-container {
    height: 70vh;
  }
}

/* @import url('/assets/fonts/Constantine.tff'); */
@font-face {
  font-family: Constantine;
  src: url(/static/media/Constantine.3ee1143b.ttf);
}

.App {
  text-align: center;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.img-header {
  position: relative;
  width: 100%;
  height: 300px;
  top: 0px;
  left: 0px;
}

.App-link {
  color: #61dafb;
}

.ap-container {
  justify-content: center;
  height: 800px;
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  flex-wrap: wrap;
}

.main-div {
  height: 100vh;
}

a {
  cursor: pointer;
}

.arcane-moose {
  width: 100px;
  height: 100px;
}

.flex-content {
  flex-grow: 1;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 60px;
}

.auther-pic {
  padding: 0px 20% 20px 20%;
  width: 300px;
}

h1 {
  padding: 10px 20% 0px 20px;
  text-align: center;
  font-family: Constantine;
}

h2 {
  text-align: center;
  padding: 0px 0px 10px 0px;
  margin: 0px 0px 0px 0px;
}

.header-title-container:hover {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.map-Gren-City {
  top: 11%;
  left: 26%;
}

.map-Hillfarm {
  top: 20.5%;
  left: 19%;
}

.map-Pasture-Downs {
  top: 24.5%;
  left: 11.5%;
}

.map-Mudplains {
  top: 30%;
  left: 12.5%;
}

.map-Meisha {
  top: 35%;
  left: 14.8%;
}

.map-Belsa {
  top: 29.5%;
  left: 35%;
}

.map-Spirit-Arboretum {
  top: 26.8%;
  left: 25.8%;
}

.map-Last-Stand {
  top: 23%;
  left: 27%;
}

.map-Tovaloreande-Sanctuary {
  top: 18%;
  left: 33%;
}

.map-Wilton {
  top: 24.5%;
  left: 36%;
}

.map-Trade-Plains {
  top: 26.5%;
  left: 58.5%;
}

.map-Oressa {
  top: 24.5%;
  left: 68.8%;
}

.map-East-Peaks {
  top: 15%;
  left: 72.2%;
}

.map-Sky-Tower {
  top: 22.5%;
  left: 77.5%;
}

.map-Hearth {
  top: 30.8%;
  left: 71%;
}

.map-Und-dal {
  top: 33.8%;
  left: 69.3%;
}

.map-Stoneport {
  top: 34.3%;
  left: 77.3%;
}

.map-Strago {
  top: 40.7%;
  left: 70.4%;
}

.map-The-Lungs {
  top: 31%;
  left: 64%;
}

.map-Ahsiem {
  top: 45.3%;
  left: 16.7%;
}

.map-Iron-Swamp {
  top: 38.3%;
  left: 32.6%;
}

.map-Weeping-Pass {
  top: 43.5%;
  left: 66.2%;
}

.map-Mizmiz {
  top: 65%;
  left: 22.4%;
}

.map-Pinpin {
  top: 74%;
  left: 16.7%;
}

.map-Clickclick-Dunes {
  top: 72.5%;
  left: 24.8%;
}

.map-Thunder-Trail {
  top: 53.8%;
  left: 78.5%;
}

.map-Rovilia {
  top: 42.5%;
  left: 85%;
}

.map-Skull-Landing {
  top: 56%;
  left: 83%;
}

.map-Phantasmal-Reef {
  top: 88%;
  left: 21%;
}

.map-Coveted-South {
  top: 90%;
  left: 60%;
}

.presskit-main-container {
  padding-top: 80px;
  font-family: 'Roboto', sans-serif;
}

.presskit-main-container h1 {
  font-weight: normal;
}

.presskit-main-container li {
  list-style: none;
}

a:hover {
  cursor: pointer;
  opacity: 0.7;
}

.presskit-sub-heading {
  font-weight: 700;
  letter-spacing: 0.03em;
}

.presskit-about-background-container {
  height: 90vh;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
          clip-path: polygon(0 0, 100% 0, 100% 95%, 70% 100%, 0 95%);
  z-index: 5;
}

.presskit-about-background-container .presskit-about-container {
  height: calc(110vh + 50px);
}

.presskit-about-background-container .presskit-about-container:before {
  height: 110vh;
  height: calc(110vh + 250px);
  position: absolute;
  width: 65vw;
  z-index: 0;
  top: -10vh;
}

.presskit-about-background-container .presskit-about-container a {
  color: black;
}

.presskit-about-background-container .presskit-about-container a:hover {
  cursor: pointer;
  opacity: 0.7;
}

.presskit-picture-description-container {
  width: 70vw;
}

h3 {
  font-family: Constantine;
}

.author-content-section {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
}

.author-content-section p {
  padding: 0px;
  margin: 0px;
  font-size: 1rem;
}

.author-content-section h3 {
  font-family: Constantine;
  font-weight: normal;
  font-size: 1.5rem;
  margin-bottom: 2px;
}

.author-photo-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.presskit-AP-Link {
  text-decoration: underline;
}

.presskit-arborealpath-container {
  background-color: #2e4a64;
  color: white;
  font-family: Roboto;
  display: flex;
  position: relative;
  padding: 0px 50px;
  margin-top: -55px;
  z-index: 1;
  position: relative;
}

.presskit-arborealpath-container a {
  color: white;
}

.presskit-arborealpath-container a:hover {
  cursor: pointer;
  color: #b9e3f791 !important;
}

.presskit-arborealpath-container .content-wrapper {
  display: flex;
  max-width: 1400px;
  margin: auto;
}

@media (max-width: 900px) {
  .presskit-arborealpath-container .content-wrapper {
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  }
}

.bookcover-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bookcover-links-container img {
  width: 300px;
}

.bookcover-links-container .photo-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-right: 25px;
}

.arborealpath-content-container {
  display: flex;
  flex-direction: column;
  padding: 70px;
}

@media (max-width: 900px) {
  .arborealpath-content-container {
    padding: 10px 0;
  }
}

.arborealpath-content-container h2 {
  text-align: left;
  font-family: Constantine;
  font-size: 1.7rem;
  padding: 0;
}

.arborealpath-content-container .reviews {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
  padding: 20px;
}

@media (max-width: 1300px) {
  .arborealpath-content-container .reviews {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1300px) {
  .arborealpath-content-container .reviews .review {
    width: 600px;
    margin: auto;
  }
}

.arborealpath-content-container .reviews .review:first-child {
  width: 293px;
  min-width: 293px;
  margin-right: 50px;
}

@media (max-width: 1300px) {
  .arborealpath-content-container .reviews .review:first-child {
    margin: 0 auto;
  }
}

@media (max-width: 900px) {
  .arborealpath-content-container .reviews .review:first-child {
    max-width: 100%;
    min-width: unset;
  }
}

.arborealpath-content-container .reviews .review:last-child {
  width: 463px;
  max-width: 463px;
}

@media (max-width: 1300px) {
  .arborealpath-content-container .reviews .review:last-child {
    margin-bottom: 20px;
  }
}

@media (max-width: 900px) {
  .arborealpath-content-container .reviews .review:last-child {
    max-width: 100%;
  }
}

.arborealpath-content-container .reviews .quote {
  font-style: italic;
}

.arborealpath-content-container .reviews .quote-by {
  margin-left: auto;
  text-align: right;
}

.arborealpath-section-container {
  display: flex;
}

.arborealpath-links-section {
  padding-left: 14vw;
}

.author-sub-section {
  font-family: 'Roboto';
  font-weight: 700;
}

.photo-link {
  font-weight: 700;
}

@media (max-width: 1200px) {
  .author-content-section p {
    font-size: 1rem;
  }
  .presskit-picture-description-container {
    max-width: 90vw;
    width: 90vw;
    padding-right: 20px;
  }
  .presskit-about-background-container {
    height: 97vh;
  }
  .presskit-about-container h1 {
    margin-top: 0px;
  }
  .author-sub-section {
    font-size: 0.9rem;
  }
  .presskit-arborealpath-container {
    flex-direction: column;
  }
  .bookcover-links-container {
    padding-top: 80px;
    align-items: center;
  }
  .bookcover-links-container img {
    width: 220px;
  }
  .presskit-main-container h1 {
    padding: 5px;
  }
  .presskit-about-container:before {
    width: 55vw;
  }
}

@media (max-width: 700px) {
  .author-content-section {
    padding-left: 0px;
  }
  .author-content-section p {
    font-size: 0.9rem;
  }
  .author-content-section h3 {
    margin-top: 2px;
  }
  .presskit-about-background-container {
    padding-top: 70px;
  }
  .presskit-about-container {
    padding-top: 5px;
    font-size: 0.8rem;
  }
  .presskit-picture-description-container {
    padding-left: 0px;
  }
  .arborealpath-section-container {
    flex-direction: column;
  }
  .arborealpath-links-section {
    padding-left: 0px;
  }
  .presskit-about-container:before {
    width: 100vw;
    left: 0vw;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 60%, 0 0);
    top: -5vh;
    height: 100vh;
  }
  .presskit-jason {
    border: 18px solid #ff9100;
  }
}

@media (max-width: 500px) {
  .presskit-about-background-container {
    height: 120vh;
  }
  .arborealpath-content-container {
    padding: 0px;
  }
  .presskit-jason {
    border-width: 12px;
  }
}
/*# sourceMappingURL=main.css.map */
